<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <a-tooltip placement="top">
          <template slot="title"><span>Add COA Master</span></template>
          <router-link :to="{ name: 'expenseCreate' }" class="btn btn-sm btn-success float-right mb-1" tag="a">
            <i class="fa fa-plus"/> Add COA Master
          </router-link>
        </a-tooltip>
        <table class="table table-sm table-bordered text-center" id="coa_master_list">
          <thead>
          <tr>
            <th>#</th>
            <th>ACC Code</th>
            <th>ACC Name</th>
            <th>COA Group</th>
            <th>COA Sub Group 1</th>
            <th>COA Sub Group 2</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(coa_master, index) in coa_masters" :key="index">
            <td :class="coa_master.group_id % 2 === 0 ? 'tdGray' : ''">{{ index + 1 }}</td>
            <td :class="coa_master.group_id % 2 === 0 ? 'tdGray' : ''">{{ coa_master.acc_code }}</td>
            <td :class="coa_master.group_id % 2 === 0 ? 'tdGray' : ''">{{ coa_master.name }}</td>
            <td :class="coa_master.group_id % 2 === 0 ? 'tdGray' : ''">{{ coa_master.coa_group.name }} - {{ coa_master.coa_group.code }}</td>
            <td :class="coa_master.group_id % 2 === 0 ? 'tdGray' : ''">{{ coa_master.coa_sub_group1 ? coa_master.coa_sub_group1.name : '' }} - {{ coa_master.coa_sub_group1 ? coa_master.coa_sub_group1.code : '' }}</td>
            <td :class="coa_master.group_id % 2 === 0 ? 'tdGray' : ''">{{ coa_master.coa_sub_group2 ? coa_master.coa_sub_group2.name : '' }} - {{ coa_master.coa_sub_group2 ? coa_master.coa_sub_group2.code : '' }}</td>
            <td :class="coa_master.group_id % 2 === 0 ? 'tdGray' : ''">
              <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);"
                 @click.prevent="onDelete(coa_master.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
            </td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="coa_masters.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      coa_masters: [],
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getCoaMasters()
  },
  methods: {
    getCoaMasters() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/expenses')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.coa_masters = response.data.coa_masters
          setTimeout(function () {
            $('#coa_master_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              })
          }, 3000)
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(id) {
      this.coa_master = {}
      this.btnEdit = true
      apiClient.get('api/expenses/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.coa_master = response.data.coa_master
        }
      })
    },
    onDelete(Id) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/expenses/' + Id).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#coa_master_list').DataTable().destroy()
              this.getCoaMasters()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.btnEdit = false
      this.coa_master = {}
    },
  },
}
</script>

<style scoped>
.tdGray {
  background: #eee;
}
</style>
